<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Configuration" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Front domain url"
                  v-model="frontDomainUrl"
                  :error="!!errors.app_frontend_domain_url"
                  :error-messages="errors.app_frontend_domain_url"
                  @input="delete errors.app_frontend_domain_url"
                />
                <va-input
                  label="Activity item per page"
                  v-model.number="activityItemPerPage"
                  :error="!!errors.app_frontend_news_per_page"
                  :error-messages="errors.app_frontend_news_per_page"
                  @input="delete errors.app_frontend_news_per_page"
                />
                <va-input
                  type="textarea"
                  label="Administrator Email"
                  v-model="administratorEmail"
                  :error="!!errors.app_email_admin"
                  :error-messages="errors.app_email_admin"
                  @input="delete errors.app_email_admin"
                />
                <va-input
                  label="Facebook page"
                  v-model="facebookPage"
                  :error="!!errors.app_link_facebook"
                  :error-messages="errors.app_link_facebook"
                  @input="delete errors.app_link_facebook"
                />
                <va-input
                  label="Youtube channel"
                  v-model="youtubeChannel"
                  :error="!!errors.app_link_youtube"
                  :error-messages="errors.app_link_youtube"
                  @input="delete errors.app_link_youtube"
                />
                <va-input
                  label="Instagram account"
                  v-model="instagramAccount"
                  :error="!!errors.app_link_instagram"
                  :error-messages="errors.app_link_instagram"
                  @input="delete errors.app_link_instagram"
                />
                <va-input
                  label="Scripts for head end"
                  v-model="scriptsHeadEnd"
                  type="textarea"
                  :error="!!errors.app_script_head_end"
                  :error-messages="errors.app_script_head_end"
                  @input="delete errors.app_script_head_end"
                />
                <va-input
                  label="Scripts for body begin"
                  v-model="scriptsBodyBegin"
                  type="textarea"
                  :error="!!errors.app_script_body_begin"
                  :error-messages="errors.app_script_body_begin"
                  @input="delete errors.app_script_body_begin"
                />
                <va-input
                  label="Scripts for body end"
                  v-model="scriptsBodyEnd"
                  type="textarea"
                  :error="!!errors.app_script_body_end"
                  :error-messages="errors.app_script_body_end"
                  @input="delete errors.app_script_body_end"
                />
                <va-input
                  label="NGO Micromark"
                  v-model="NGOmicromark"
                  type="textarea"
                  :error="!!errors.app_ngo_micromark"
                  :error-messages="errors.app_ngo_micromark"
                  @input="delete errors.app_ngo_micromark"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      frontDomainUrl: '',
      activityItemPerPage: '',
      administratorEmail: '',
      facebookPage: '',
      youtubeChannel: '',
      instagramAccount: '',
      scriptsHeadEnd: '',
      scriptsBodyBegin: '',
      scriptsBodyEnd: '',
      NGOmicromark: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        app_frontend_domain_url: this.frontDomainUrl,
        app_frontend_news_per_page: this.activityItemPerPage,
        app_email_admin: this.administratorEmail,
        app_link_facebook: this.facebookPage,
        app_link_youtube: this.youtubeChannel,
        app_link_instagram: this.instagramAccount,
        app_script_head_end: this.scriptsHeadEnd,
        app_script_body_begin: this.scriptsBodyBegin,
        app_script_body_end: this.scriptsBodyEnd,
        app_ngo_micromark: this.NGOmicromark,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-config?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-config/validate?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-config?lang=${this.locale}`)
        .then(response => {
          // console.log(response.data)
          this.frontDomainUrl = response.data.app_frontend_domain_url
          this.activityItemPerPage = response.data.app_frontend_news_per_page
          this.administratorEmail = response.data.app_email_admin
          this.facebookPage = response.data.app_link_facebook
          this.youtubeChannel = response.data.app_link_youtube
          this.instagramAccount = response.data.app_link_instagram
          this.scriptsHeadEnd = response.data.app_script_head_end
          this.scriptsBodyBegin = response.data.app_script_body_begin
          this.scriptsBodyEnd = response.data.app_script_body_end
          this.NGOmicromark = response.data.app_ngo_micromark
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
